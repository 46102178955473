import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Swiper, SwiperSlide } from "swiper/react"
import { tariff } from '../../dict/tariffDict'

// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

const dayWeek = ['poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota', 'niedziela']

const slideList = (min, max, name, step) => {
    const tableList = []
    if (step && step !== 1) {
        let helpmin = min / step
        let helpmax = max / step
        for (let i = helpmin; i <= helpmax; i++) {
            tableList.push(<SwiperSlide key={name + i}>{Number.isInteger(i * step) ? (i * step) : (i * step).toFixed(1)}</SwiperSlide>)
        }
    } else {
        if (name === 'BLK_LK') {
            tableList.push(<SwiperSlide key={'auto'}>Auto</SwiperSlide>)
        }
        for (let i = min; i <= max; i++) {
            if ((name === "ECOY1" || name === "ECOX1" || name === "ECOY2" || name === "ECOX2") && i !== 0)
                tableList.push(<SwiperSlide key={name + i}>+ {i}</SwiperSlide>)
            else if ((name === "ECOTIN" || name === "ECODHW") && i !== 0)
                tableList.push(<SwiperSlide key={name + i}>- {i}</SwiperSlide>)
            else if (name === "ST_DZIEN_TYG")
                tableList.push(<SwiperSlide key={name + i}>{dayWeek[i]}</SwiperSlide>)
            else if(name === "tariffEnergy")
                tableList.push(<SwiperSlide key={name + i}>{tariff.tariffDictEnergy[i]}</SwiperSlide>)
            else if(name === "tariffDistribution")
                tableList.push(<SwiperSlide key={name + i}>{tariff.tariffDictDistribution[i]}</SwiperSlide>)
            else
                tableList.push(<SwiperSlide key={name + i}>{i}</SwiperSlide>)
        }
    }
    return tableList
}

const initSlideIndex = (step, initVal) => {
    let helpVal
    if (!step)
        helpVal = initVal
    else if (step === 5)
        helpVal = Math.round(initVal / step)
    else
        helpVal = initVal / step

    
    return helpVal
}

const CarouselInputMulti = ({ init, min, max, name, setVal, num, step }) => {
    const [slide, setSlide] = useState(init)

    const onChangeSlideTime = (index) => {
        setSlide(index - 1)
    }
    const onChangeStop = (index, value) => {
        // console.log(value[index].textContent.replace(/\D+/gi, ""))
        console.log({value})
        if (index === 0 && name === 'BLK_LK') {
            setVal(num, 100)
        } else if (name === "tariffEnergy") {
            setVal(value[index].firstChild.data, "ENE_TAR")
        } else if (name === "tariffDistribution") {
            setVal(value[index].firstChild.data, "DIS_TAR")
        } else if (name === "CO_ECO_COEFF" || name === "CWU_ECO_COEFF" || name === "SPHT_ZAD" || name === "SPDHW_ZAD") {
            setVal(parseFloat(value[index].firstChild.data), name)
        } else if (name === "ST_DZIEN_TYG") {
            setVal(num, dayWeek.findIndex(day => day === value[index].textContent))
        } else {
            setVal(num, parseInt(value[index].textContent.replace(/\D+/gi, "")))
        }
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <Swiper
                    // Jeśli dobrze widzę to ta funkcja jest zbędana - Szymon
                    // onTouchEnd={(swiper) => onChangeSlideTime(swiper.activeIndex)}
                    initialSlide={initSlideIndex(step, init) - (min !== 0 && step !== 1 ? min / step : name === 'BLK_LK' && init === 100 ? 100 : name === 'BLK_LK' ? min - 1 : min)}
                    watchSlidesVisibility={true} slidesPerView={3}
                    centeredSlides={true} direction="vertical" loop={false} className="mySwiper"
                    // loopAdditionalSlides={18}
                    onSlideChange={(swiper) => {
                        console.log({ swiperIndex: swiper })
                        return onChangeStop(swiper.activeIndex, swiper.slides)
                    }}>
                    {slideList(min, max, name, step)}
                </Swiper>
                
                
            </Grid>
        </Grid>
    )
}

export default CarouselInputMulti