import React, { useState, useEffect } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { useRouteMatch } from "react-router-dom"
import { Link } from 'react-router-dom'
import { iconDict } from '../../dict'
import { Gauge } from '../'

const colorConfig = (valPrecentMeter) => {
        
  let caseNumber = 0

  if (valPrecentMeter <= 59)
      caseNumber = 1
  else if (valPrecentMeter <= 79)
      caseNumber = 2
  else if (valPrecentMeter > 79)
      caseNumber = 3
  else
      caseNumber = 0

  switch (caseNumber) {
      case 1:
          return '#009245'
      case 2:
          return '#ff9515'
      case 3:
          return '#d62027'
      default:
          return '#5a5a5a'
  }
}

const FrameMeter = ({ meter, numberMeter, activeSlideSwiper }) => {
  const [meterState, setMeterState] = useState(null)
  const [precentState, setPrecentState] = useState(null)
  const [colorState, setColorState] = useState(null)
  let { url } = useRouteMatch()
  useEffect(() => {
    let mounted = true

    if (meter) {
      // console.log({meter})
      setMeterState(meter)
      setPrecentState(Math.round((Math.abs(meter.meter.TOTAL_ACT_POW_FL_1) / 11) * 100) >= 100 ? 100
        : Math.round((Math.abs(meter.meter.TOTAL_ACT_POW_FL_1) / 11) * 100) <= 0 ? 0
          : Math.round((Math.abs(meter.meter.TOTAL_ACT_POW_FL_1) / 11) * 100))
        setColorState(colorConfig(Math.round((Math.abs(meter.meter.TOTAL_ACT_POW_FL_1) / 11) * 100) >= 100 ? 100
          : Math.round((Math.abs(meter.meter.TOTAL_ACT_POW_FL_1) / 11) * 100) <= 0 ? 0
            : Math.round((Math.abs(meter.meter.TOTAL_ACT_POW_FL_1) / 11) * 100)))
    } else {
      setMeterState({
        meterDevice: {
          Name: `METER_${numberMeter}`,
          Type: "METER",
        },
        meter: {
          TOTAL_ACT_POW_FL_1: -3.5
        }
      })
      setPrecentState(0)
      setColorState("#5a5a5a")
    }
    return () => {
      mounted = false
    }
  }, [meter])

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={4}>
          {meterState && meterState.meterDevice ? <img className='meterIcon' alt={meterState.meterDevice.Name} src={iconDict[meterState.meterDevice.Name].img} /> : null}

        </Grid>
        <Grid item xs={8}>
          {meterState && meterState.meterDevice ? <Typography variant='body' className='meterTitle'>{iconDict[meterState.meterDevice.Name].name}</Typography> : null}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {meterState && meterState.meter && meterState.meter.TOTAL_ACT_POW_FL_1 && precentState >= 0?
            <Gauge symbol="kW" nameMeter={iconDict[meterState.meterDevice.Name].name} 
            valueMet={meterState.meter.TOTAL_ACT_POW_FL_1}
              valPrecentMeter={precentState} 
              maxVal={11}
              // lenght1={precentState / 100} lenght2={(100 - precentState) / 100} 
              //   valueSymbol={`${meterState.meter.TOTAL_ACT_POW_FL_1.toLocaleString("fr-US", {
                //     minimumFractionDigits: 2,
                //     maximumFractionDigits: 2,
                // })} kW`} 
                // color={colorState} 
            // valueSymbol={meterState.meter.TOTAL_ACT_POW_FL_1}
            />
            : null}
            
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {meterState && meterState.meterDevice ?
            <Link to={{
              pathname: `${url}/meterinfo`,
              state: {
                meter: meterState,
                name: iconDict[meterState.meterDevice.Name].name,
                activeSlideSwiper: activeSlideSwiper,
                infoURL: `${url}`
              }
            }}><div className="parametry-icon">
                <img alt="parametry" className="mode-icon" src={iconDict.PARAMETRY} />
                <Typography variant="body">PARAMETRY</Typography>
              </div></Link> : null}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default FrameMeter