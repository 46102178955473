const tariffDictEnergy = [
    "PGE G11",
    "PGE G12",
    "PGE G12r",
    "PGE G12w",
    "Energa G11",
    "Energa G12",
    "Energa G12r",
    "Energa G12w"
]
const tariffDictDistribution = [
    "PGE G11",
    "PGE G12",
    "PGE G12r",
    "PGE G12w",
    "Energa G11",
    "Energa G12",
    "Energa G12r",
    "Energa G12w"
]

export const tariff = {
    tariffDictEnergy, 
    tariffDictDistribution
}