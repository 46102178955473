import { Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { GaugeComponent } from 'react-gauge-component'

const Gauge = ({ nameMeter, valueMet, valPrecentMeter, symbol, addClass, lenght1, lenght2, color, valueSymbol, maxVal }) => {

    const colorConfig = () => {
        
        let caseNumber = 0

        if (valPrecentMeter <= 59)
            caseNumber = 1
        else if (valPrecentMeter <= 79)
            caseNumber = 2
        else if (valPrecentMeter > 79)
            caseNumber = 3
        else
            caseNumber = 0

        switch (caseNumber) {
            case 1:
                return '#009245'
            case 2:
                return '#ff9515'
            case 3:
                return '#d62027'
            default:
                return '#5a5a5a'
        }
    }

    

    return (
        <React.Fragment>
            <GaugeComponent
                id="guagemeter"
                type="radial"
                className={parseFloat(valueMet) < 0 ? `revers ${addClass ? 'bigGauge' : ''}` : `normal ${addClass ? 'bigGauge' : ''}`}
                marginInPercent={0.035}
                arc={{
                    gradient: false,
                    emptyColor: "#5a5a5a",
                    width: 0.085,
                    padding: 0.005,
                    cornerRadius: 10,
                    subArcs: [
                        {
                            length: valueMet,
                            color: colorConfig(),
                        },
                        {
                            length: maxVal - valueMet,
                            color: '#5a5a5a',
                        },
                    ]
                }}
                value={parseFloat(valueMet)}
                maxValue={parseInt(maxVal)}
                minValue={0}
                pointer={{ hide: true, animate: true }}

                labels={{
                    tickLabels: {
                        hideMinMax: true,
                        hide: true
                    },
                    valueLabel: {
                        formatTextValue: value => `${valueMet.toLocaleString("fr-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })} ${symbol}`,
                        style: {
                            fontSize: 50,
                        },
                    }
                }}
            />
        </React.Fragment>
    )
}

export default Gauge