import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import chmurka_temp_zew from '../../../../img/chmurka_temp_zew.svg'
import temp_w_domu_chlodzenie from '../../../../img/temp-w-domu-chlodzenie.svg'
import temp_w_domu_chlodzenie_1 from '../../../../img/temp_w_domu_chlodzenie_1.svg'
import temp_w_domu_grzanie from '../../../../img/temp-w-domu-grzanie.svg'
import temp_w_domu_grzanie_1 from '../../../../img/temp_w_domu_grzanie_1.svg'
import temp_w_domu_grzanie_2 from '../../../../img/temp_w_domu_grzanie_2.svg'
import temp_w_domu_grzanie_3 from '../../../../img/temp_w_domu_grzanie_3.svg'
import temp_w_domu_grzanie_4 from '../../../../img/temp_w_domu_grzanie_4.svg'
import temp_w_domu_czuwanie from '../../../../img/temp-w-domu-ok.svg'
import temp_w_domu_czuwanie_1 from '../../../../img/temp_w_domu_ok_1.svg'
import temp_w_domu_czuwanie_2 from '../../../../img/temp_w_domu_ok_2.svg'
import temp_w_domu_czuwanie_3 from '../../../../img/temp_w_domu_ok_3.svg'
import temp_w_domu_czuwanie_4 from '../../../../img/temp_w_domu_ok_4.svg'
import temp_wody_grzanie from '../../../../img/temp-wody-grzanie.svg'
import temp_wody_ok from '../../../../img/temp-wody-ok.svg'
import bez_temp_w_domu_chlodzenie from '../../../../img/bez_temp_w_domu_chlodzenie.svg'
import bez_temp_w_domu_chlodzenie_1 from '../../../../img/bez_temp_w_domu_chlodzenie_1.svg'
import bez_temp_w_domu_grzanie from '../../../../img/bez_temp_w_domu_grzanie.svg'
import bez_temp_w_domu_grzanie_1 from '../../../../img/bez_temp_w_domu_grzanie_1.svg'
import bez_temp_w_domu_grzanie_2 from '../../../../img/bez_temp_w_domu_grzanie_2.svg'
import bez_temp_w_domu_grzanie_3 from '../../../../img/bez_temp_w_domu_grzanie_3.svg'
import bez_temp_w_domu_grzanie_4 from '../../../../img/bez_temp_w_domu_grzanie_4.svg'
import bez_temp_w_domu_czuwanie from '../../../../img/bez_temp_w_domu_czuwanie.svg'
import bez_temp_w_domu_czuwanie_1 from '../../../../img/bez_temp_w_domu_czuwanie_1.svg'
import bez_temp_w_domu_czuwanie_2 from '../../../../img/bez_temp_w_domu_czuwanie_2.svg'
import bez_temp_w_domu_czuwanie_3 from '../../../../img/bez_temp_w_domu_czuwanie_3.svg'
import bez_temp_w_domu_czuwanie_4 from '../../../../img/bez_temp_w_domu_czuwanie_4.svg'
import bez_temp_w_domu_off from '../../../../img/bez_temp_w_domu_off.svg'
import bez_temp_w_domu_off_1 from '../../../../img/bez_temp_w_domu_off_1.svg'
import bez_temp_w_domu_off_2 from '../../../../img/bez_temp_w_domu_off_2.svg'
import bez_temp_w_domu_off_3 from '../../../../img/bez_temp_w_domu_off_3.svg'
import bez_temp_w_domu_off_4 from '../../../../img/bez_temp_w_domu_off_4.svg'
import temp_w_domu_off from '../../../../img/temp_w_domu_off.svg'
import temp_w_domu_off_1 from '../../../../img/temp_w_domu_off_1.svg'
import temp_w_domu_off_2 from '../../../../img/temp_w_domu_off_2.svg'
import temp_w_domu_off_3 from '../../../../img/temp_w_domu_off_3.svg'
import temp_w_domu_off_4 from '../../../../img/temp_w_domu_off_4.svg'
import temp_wody_off from '../../../../img/temp_wody_off.svg'
import antyzamrozenie from '../../../../img/antyzamrozenie.svg'
import bateria_sprawna from '../../../../img/bateria_sprawna.svg'
import bateria_uszkodzona from '../../../../img/bateria_uszkodzona.svg'
import { EMSInfo, HeatingCircuit, HeatingCircuit2, HeatingCircuitData, CWUCircuit, Statistics, Scada, MobileEuros, ScadaTest, ScadaCircuit2, ScadaNCBR, HeatingCircuit3, HeatingCircuit4, HeatingCircuitAddditional, HeatingCircuitAdditional } from './pages'
import { Swiper, SwiperSlide } from 'swiper/react'

// swiper bundle styles
import 'swiper/swiper-bundle.min.css'

// swiper core styles
import 'swiper/swiper.min.css'

// modules styles
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import SwiperCore, {
    Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);

const grzanieBez = [
    bez_temp_w_domu_grzanie_2,
    bez_temp_w_domu_grzanie_3,
    bez_temp_w_domu_grzanie_4,
]
const czuwanieBez = [
    bez_temp_w_domu_czuwanie_2,
    bez_temp_w_domu_czuwanie_3,
    bez_temp_w_domu_czuwanie_4,
]
const offBez = [
    bez_temp_w_domu_off_2,
    bez_temp_w_domu_off_3,
    bez_temp_w_domu_off_4,
]
const grzanie = [
    temp_w_domu_grzanie_2,
    temp_w_domu_grzanie_3,
    temp_w_domu_grzanie_4,
]
const czuwanie = [
    temp_w_domu_czuwanie_2,
    temp_w_domu_czuwanie_3,
    temp_w_domu_czuwanie_4,
]
const off = [
    temp_w_domu_off_2,
    temp_w_domu_off_3,
    temp_w_domu_off_4,
]

const TempInfo = ({ settings, hcircuit, circ, num }) => {

    const deviceName = sessionStorage.getItem('deviceName')
    const version_1 = parseFloat(sessionStorage.getItem('version_1'))
    const version_2 = parseFloat(sessionStorage.getItem('version_2'))

    /* useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
      }, []); */    
    
    return (
        <Grid container style={{ position: "relative", top: 0 }}>
            <Grid item xs={4}>
                <Grid container>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                        {/*<img alt="antyzamrożenie" className="small-icon" src={antyzamrozenie} />*/}
                        {(((version_1 > 4) || (version_1 === 4 && version_2 >= 92)) && (deviceName === 'ATMO_1' || deviceName === 'ALLINONE_1')) && settings.temp.SXB_F4DP === 1 ?
                            settings.temp.SXB_4DR === 1 ?
                                <img src={bateria_uszkodzona} alt="" title="" className='bateria-uszkodzona'/> 
                                : <img src={bateria_sprawna} alt="" title="" className='bateria-sprawna'/>
                            : null
                        }
                    </Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Grid container style={{ position: "relative", top: 0 }}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4} style={{ position: "relative", top: 0, height: "60px" }}>
                        <img alt="temperatura zewnętrzna" className="small-icon" style={{ width: "50px", position: "absolute", bottom: 0, left: 0 }} src={chmurka_temp_zew} />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5} className="table-display" style={{ position: "relative", top: 0, height: "60px" }}>
                    {settings.temp.TSM9 < -45 ?
                        <Typography variant="h2" style={{ position: "absolute", bottom: 0 }}>E 1</Typography>
                        : settings.temp.TSM9 > 145 ?
                            <Typography variant="h2" style={{ position: "absolute", bottom: 0 }}>E 2</Typography>
                            : <Typography variant="h2" style={{ position: "absolute", bottom: 0, right: 0 }}>{settings.temp.TSM9.toFixed(1)}<sup>&deg;C</sup></Typography>
                    }  
                    </Grid>
                </Grid>
                <Grid container style={{ position: "relative", top: 0 }}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4} style={{ position: "relative", height: "68px", top: 0 }}>
                        {hcircuit ?
                            settings.temp.SWEXT_BUF === 1 ? 
                                <Grid item>
                                    {settings.temp.SBF_OOF === 1 ? 
                                        settings.temp.SXF_TOE === 1 && settings.temp.SBF_OOF === 1 ?
                                            settings.temp.SBB_QHT === 1 ? <img alt="temperatura w domu - grzanie" className="small-icon" src={grzanie[num]} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                                : settings.temp.SBB_QHL === 1 ? <img alt="temperatura w domu - właściwa" className="small-icon" src={czuwanie[num]} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                                    : <img alt="temperatura w domu - właściwa" className="small-icon" src={czuwanie[num]} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                            : <img alt="temperatura w domu - właściwa" className="small-icon" src={czuwanie[num]} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                        : <img alt="urządzenie wyłączone" className="small-icon" src={off[num]} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                    }
                                </Grid>
                                :
                                <Grid item>
                                    {settings.temp.SBF_OOF === 1 ? 
                                        settings.temp.SXF_TOE === 1 && settings.temp.SBF_OOF === 1 ?
                                            settings.temp.SBB_QHT === 1 ? <img alt="temperatura w domu - grzanie" className="small-icon" src={grzanieBez[num]} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                                : settings.temp.SBB_QHL === 1 ? <img alt="temperatura w domu - właściwa" className="small-icon" src={czuwanieBez[num]} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                                    : <img alt="temperatura w domu - właściwa" className="small-icon" src={czuwanieBez[num]} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                            : <img alt="temperatura w domu - właściwa" className="small-icon" src={czuwanieBez[num]} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                        : <img alt="urządzenie wyłączone" className="small-icon" src={offBez[num]} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                    }
                                </Grid>
                                : 
                            settings.temp.SWEXT_BUF === 1 ? 
                                <Grid item>
                                    {settings.temp.SBF_OOF === 1 ? 
                                        settings.temp.SXF_TOE === 1 && settings.temp.SBF_OOF === 1 ?
                                            settings.temp.SBB_QHT === 1 ? <img alt="temperatura w domu - grzanie" className="small-icon" src={settings.temp.OB1_XZM_TRANSM === 0 ? temp_w_domu_grzanie_1 : temp_w_domu_grzanie} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                                : settings.temp.SBB_QHL === 1 ? <img alt="temperatura w domu - chłodzenie" className="small-icon" src={settings.temp.OB1_XZM_TRANSM === 0 ? temp_w_domu_chlodzenie_1 : temp_w_domu_chlodzenie} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                                    : <img alt="temperatura w domu - właściwa" className="small-icon" src={settings.temp.OB1_XZM_TRANSM === 0 ? temp_w_domu_czuwanie_1 : temp_w_domu_czuwanie} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                            : <img alt="temperatura w domu - właściwa" className="small-icon" src={settings.temp.OB1_XZM_TRANSM === 0 ? temp_w_domu_czuwanie_1 : temp_w_domu_czuwanie} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                        : <img alt="urządzenie wyłączone" className="small-icon" src={settings.temp.OB1_XZM_TRANSM === 0 ? temp_w_domu_off_1 : temp_w_domu_off} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                    }
                                </Grid>
                                :
                                <Grid item>
                                    {settings.temp.SBF_OOF === 1 ? 
                                        settings.temp.SXF_TOE === 1 && settings.temp.SBF_OOF === 1 ?
                                            settings.temp.SBB_QHT === 1 ? <img alt="temperatura w domu - grzanie" className="small-icon" src={settings.temp.OB1_XZM_TRANSM === 0 ? bez_temp_w_domu_grzanie_1 : bez_temp_w_domu_grzanie} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                                : settings.temp.SBB_QHL === 1 ? <img alt="temperatura w domu - chłodzenie" className="small-icon" src={settings.temp.OB1_XZM_TRANSM === 0 ? bez_temp_w_domu_chlodzenie_1 : bez_temp_w_domu_chlodzenie} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                                    : <img alt="temperatura w domu - właściwa" className="small-icon" src={settings.temp.OB1_XZM_TRANSM === 0 ? bez_temp_w_domu_czuwanie_1 : bez_temp_w_domu_czuwanie} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                            : <img alt="temperatura w domu - właściwa" className="small-icon" src={settings.temp.OB1_XZM_TRANSM === 0 ? bez_temp_w_domu_czuwanie_1 : bez_temp_w_domu_czuwanie} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                        : <img alt="urządzenie wyłączone" className="small-icon" src={settings.temp.OB1_XZM_TRANSM === 0 ? bez_temp_w_domu_off_1 : bez_temp_w_domu_off} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                    }
                                </Grid>
                        }
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5} className="table-display" style={{ position: "relative", height: "68px", top: 0 }}>
                        {settings.temp[circ] < -45 ?
                            <Typography variant="h2" style={{ position: "absolute", bottom: 0 }}>E 1</Typography>
                            : settings.temp[circ] > 145 ?
                                <Typography variant="h2" style={{ position: "absolute", bottom: 0 }}>E 2</Typography>
                                : <Typography variant="h2" style={{ position: "absolute", bottom: 0, right: 0 }}>{settings.temp[circ].toFixed(1)}<sup>&deg;C</sup></Typography>
                        }
                    </Grid>
                </Grid>
                <Grid container style={{ position: "relative", top: 0 }}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4} style={{ position: "relative", height: "68px", top: 0 }}>
                        {settings.temp.SBF_OOF === 1 ? 
                            settings.temp.SBF_DHS === 1 && settings.temp.SBF_DHX === 1 && settings.temp.SBB_QHW === 1 ?
                                <img alt="temperatura wody - grzanie" className="small-icon" src={temp_wody_grzanie} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                                : <img alt="temperatura wody - właściwa" className="small-icon" src={temp_wody_ok} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                            : <img alt="urządzenie wyłączone" className="small-icon" src={temp_wody_off} style={{ width: "68px", position: "absolute", bottom: 0, left: 0 }} />
                        }
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5} className="table-display" style={{ position: "relative", height: "68px", top: 0 }}>
                    {settings.temp.TSM15 < -45 ?
                        <Typography variant="h2" style={{ position: "absolute", bottom: 0 }}>E 1</Typography>
                        : settings.temp.TSM15 > 145 ?
                            <Typography variant="h2" style={{ position: "absolute", bottom: 0 }}>E 2</Typography>
                            : <Typography variant="h2" style={{ position: "absolute", bottom: 0, right: 0 }}>{settings.temp.TSM15.toFixed(1)}<sup>&deg;C</sup></Typography>
                    }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const CenterSide = ({ settings, extSettings, setPublish, modes, statistics, scada, url, transmState, meterSettings, expirationData, urlName }) => {
    const paginationCustom = {
        "clickable": true,
    }
    const [activeSlideSwiper, setActiveSlideSwiper] = useState(0)
    let location = useLocation()
    const isExt = parseFloat(sessionStorage.getItem('isExt'))

    const getAdditionalScadaArr = () => {
        return (
            Object.keys(settings.temp.map).reduce((addArr, item, index) => { 
            const chunkIndex = Math.floor(index / 3)
                
            if(!addArr[chunkIndex]) {
              addArr[chunkIndex] = []
            }
          
            addArr[chunkIndex].push(item === undefined ? 0 : item.slice(2,3))
            
            return addArr
          }, [])
        )
    }   

    return (
        <React.Fragment>
            <Swiper
                initialSlide={location && location.state && location.state.initInfo ? location.state.initInfo : 0}
                spaceBetween={50}
                slidesPerView={1}
                //onSlideChange={() => console.log('slide change')}
                //onSwiper={(swiper) => console.log(swiper)}
                navigation={false}
                pagination={paginationCustom}
                onSlideChange={(swiper) => {
                    setActiveSlideSwiper(swiper.activeIndex)
                }}
            >
                <SwiperSlide>
                    <TempInfo expirationData={expirationData} settings={settings} hcircuit={false} circ={'TSM10'} />
                    <HeatingCircuit urlName={urlName} modes={modes} settings={settings.temp} setPublish={setPublish} />
                </SwiperSlide>
                <SwiperSlide>
                    <TempInfo expirationData={expirationData} settings={settings} hcircuit={false} circ={'TSM10'} />
                    <CWUCircuit urlName={urlName} modes={modes} settings={settings.temp} setPublish={setPublish} />
                </SwiperSlide>
                {settings.temp.map ? Object.entries(settings.temp.map).map((entry, index) => {
                    if (entry[1] === 0) {
                        return (
                            <SwiperSlide key={entry[0]}>
                                <TempInfo expirationData={expirationData} settings={settings} hcircuit={true} circ={parseInt(entry[0].slice(2, 4)) > 9 ? `${entry[0].slice(0, 4)}_TWEW` : `${entry[0].slice(0, 3)}_TWEW`} num={parseInt(entry[0].slice(2, 4)) > 9 ? parseInt(entry[0].slice(2, 4)) - 1 : parseInt(entry[0].slice(2, 3)) - 1} />
                                <HeatingCircuitAdditional urlName={urlName} modes={modes} settings={settings.temp} setPublish={setPublish} num={parseInt(entry[0].slice(2, 4)) > 9 ? parseInt(entry[0].slice(2, 4)) : parseInt(entry[0].slice(2, 3))} />
                            </SwiperSlide>
                        )
                    } else {
                        return null
                    }
                }) : null
                }
                {/*{settings.temp.OB1_XZM_TRANSM === 0 ?
                    <React.Fragment><SwiperSlide key="HeatingCircuit2">
                        <TempInfo settings={settings} hcircuit={true} circ={'OB1_TWEW'} num={0} />
                        <HeatingCircuit2 modes={modes} settings={settings.temp} setPublish={setPublish} />
                    </SwiperSlide>
                    </React.Fragment> 
                    : null
                }
                {settings.temp.OB2_XZM_TRANSM === 0 && settings.temp.OB2_TWEW < 200 ?
                    <React.Fragment><SwiperSlide key="HeatingCircuit3">
                        <TempInfo settings={settings} hcircuit={true} circ={'OB2_TWEW'} num={3} />
                        <HeatingCircuit3 modes={modes} settings={settings.temp} setPublish={setPublish} />
                    </SwiperSlide>
                    </React.Fragment> 
                    : null
                }
                {settings.temp.OB3_XZM_TRANSM === 0 && settings.temp.OB3_TWEW < 200 ?
                    <React.Fragment><SwiperSlide key="HeatingCircuit4">
                        <TempInfo settings={settings} hcircuit={true} circ={'OB3_TWEW'} num={4} />
                        <HeatingCircuit4 modes={modes} settings={settings.temp} setPublish={setPublish} />
                    </SwiperSlide>
                  </React.Fragment> 
                    : null
                }*/}
                {/*<SwiperSlide><Scada scada={scada} /></SwiperSlide>*/}
                <SwiperSlide>
                    <EMSInfo urlName={urlName} activeSlideSwiper={activeSlideSwiper} meterSettings={meterSettings} />
                </SwiperSlide>
                {!Object.keys(extSettings)[0] || isExt === 0 ?
                    <SwiperSlide>
                        <ScadaTest scada={scada} transmState={transmState} />
                    </SwiperSlide>
                    : Object.entries(extSettings).map(entry => {
                        return (
                            <SwiperSlide key={entry[0]}>
                                <ScadaNCBR scada={extSettings[`extender_${entry[0].slice(-1)}`].extScada} />
                            </SwiperSlide>
                        )
                    })
                }
                {settings.temp.OB1_XZM_TRANSM === 0 ?
                    Object.keys(getAdditionalScadaArr()).map(index => {
                        return (
                            <SwiperSlide key={index}>
                                <ScadaCircuit2 scada={scada} circArr={getAdditionalScadaArr()[index]} />
                            </SwiperSlide>
                        )
                    })
                    : null
                }
                <SwiperSlide><Statistics settings={statistics} /></SwiperSlide>
                <SwiperSlide><MobileEuros /></SwiperSlide>
            </Swiper>
        </React.Fragment>
    )
}

export default CenterSide