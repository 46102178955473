import React, { useState, useEffect } from 'react'
import { Grid, Typography, Switch, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import setpoint_CWU_czuwanie from '../../../../../../img/setpoint-CWU-czuwanie.svg'
import setpoint_CWU_grzanie from '../../../../../../img/setpoint-CWU-grzanie.svg'
import setpoint_CWU_off from '../../../../../../img/CWU-off.svg'
import edycja from '../../../../../../img/edit.svg'
import X from '../../../../../../img/x.svg'
import { mainStyleClasses } from '../../../../../../theme'

import { CarouselInputMulti } from '../../../../../../components'

const CWUCircuit = ({ settings, setPublish, modes, urlName }) => {
    const classes = mainStyleClasses()
    const [state, setState] = useState(settings.SBF_DHS);
    const [itemValue, setItemValue] = useState(settings)
    const [open, setOpen] = useState({ status: false, val: null, name: "" })
    const [gotopage, setGoToPage] = useState("")

    let hpTemp = (itemValue.SPDHW_ZAD + itemValue.SCDHW) < 20 ? 20 : (itemValue.SPDHW_ZAD + itemValue.SCDHW) > 70 ? 70 : itemValue.SPDHW_ZAD + itemValue.SCDHW
    let realTemp = modes.SBF_OOF === 0 ? "off" : modes.SXF_ECO === 1 || modes.SXF_TIME === 1 ? hpTemp : itemValue.SPDHW_ZAD

    const handleClickOpen = () => {
        if (modes.SXF_TIME === 1) {
            setGoToPage(`${urlName}/timemode/timecwu`)
        } else if (modes.EMS_DATA === true && modes.SXF_AWA !== 1 && modes.U_ENE_TAR === 1 && modes.U_PV_DATA === 1) {
            setGoToPage(`${urlName}/metersettings`)
        } else if (modes.SXF_ECO === 1 && modes.SXF_AWA !== 1) {
            setGoToPage(`${urlName}/ecomode`)
        } else if (modes.SBF_OOF === 0) {
            return
        } else {
            setOpen({ val: modes.SXF_AWA === 1 ? settings.AWAY_CLOCK : settings.SPDHW_ZAD, name: modes.SXF_AWA === 1 ? 'AWAY_CLOCK' : 'SPDHW_ZAD', status: true })
        }
    }

    const handleClose = () => {
        setItemValue({ [open.name]: open.val })
        setPublish({ [open.name]: open.val })
        setOpen({ ...open, status: false })
    }

    const handleChange = () => {
        if (state === 0) {
            setState(1)
            setPublish({ SBF_DHS: 1 })
        } else {
            setState(0)
            setPublish({ SBF_DHS: 0 })
        }
    }

    const setNewValue = (num, val) => {
        setOpen({
            ...open,
            name: num,
            val: val
        })
        //setPublish({ [num]: val })
    }

    useEffect(() => {
        let mounted = true

        // eslint-disable-next-line react-hooks/exhaustive-deps
        hpTemp = (itemValue.SPDHW_ZAD + itemValue.SCDHW) < 20 ? 20 : (itemValue.SPDHW_ZAD + itemValue.SCDHW) > 70 ? 70 : itemValue.SPDHW_ZAD + itemValue.SCDHW
        // eslint-disable-next-line react-hooks/exhaustive-deps
        realTemp = modes.SBF_OOF === 0 ? "off" : modes.SXF_ECO === 1 || modes.SXF_TIME === 1 ? hpTemp : itemValue.SPDHW_ZAD
        
        setState(settings.SBF_DHS)
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings, modes])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={3} style={{ position: 'relative' }}>
                    {settings.SBF_DHS === 1 && settings.SBF_DHX === 1 && settings.SBF_OOF === 1 ?
                        settings.SBB_QHW === 1 ?
                            <img alt="obieg ciepłej wody użytkowej - grzanie" className="mode-icon" src={setpoint_CWU_grzanie} style={{ position: 'absolute', bottom: '0px', right: '25px' }} />
                            : <img alt="obieg ciepłej wody użytkowej - czuwanie" className="mode-icon" src={setpoint_CWU_czuwanie} style={{ position: 'absolute', bottom: '0px', right: '25px' }} />
                        : <img alt="obieg ciepłej wody użytkowej - wyłączone" className="mode-icon" src={setpoint_CWU_off} style={{ position: 'absolute', bottom: '0px', right: '25px' }} />
                    }
                </Grid>
                <Grid item xs={6}><Typography variant="h1">{modes.SBF_OOF === 0 ? <React.Fragment>off<sup> </sup></React.Fragment> : modes.SXF_AWA === 1 ? (<React.Fragment>{itemValue.AWAY_CLOCK}<sup>dni</sup></React.Fragment>) : (<React.Fragment>{parseFloat(realTemp)}<sup>&deg;C</sup></React.Fragment>)}</Typography></Grid>
                <Grid item xs={3} style={{ position: 'relative' }}>
                    <img alt="edycja" onClick={handleClickOpen} className="mode-icon" src={edycja} style={{ position: 'absolute', bottom: '0px', left: '25px' }} />
                    {gotopage && gotopage !== "" ? <Redirect to={gotopage} /> : null}
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: '50px' }}>
                <Grid item xs={12}>
                    {settings.SBF_DHX === 0 ?
                        <Typography variant="h3" style={{ display: "inline-block", marginRight: '20px' }}>Obieg ciepłej wody użytkowej niedostępny</Typography>
                        : <Grid item>
                            <Typography variant="h3" style={{ display: "inline-block", marginRight: '20px' }}>Obieg ciepłej wody użytkowej</Typography>
                            <Switch
                                style={{ display: "inline-block" }}
                                checked={state === 1 ? true : false}
                                onChange={handleChange}
                                color="primary"
                                name="SBF_DHS"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Dialog scroll={"paper"} fullWidth={true} maxWidth={"sm"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <img alt="X" style={{ height: '20px' }} src={X} />
                    </IconButton>
                    <Grid container>
                        <Grid item xs={12}><Typography variant="h3">{modes.SXF_AWA === 1 ? "Liczba dni urlopu" : "Ustaw temperaturę"}</Typography></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6} className="carousel">
                        <CarouselInputMulti init={modes.SXF_AWA === 1 ? settings.AWAY_CLOCK : settings.SPDHW_ZAD} name={modes.SXF_AWA === 1 ? `AWAY_CLOCK` : `SPDHW_ZAD`} min={modes.SXF_AWA === 1 ? 0 : 35} max={modes.SXF_AWA === 1 ? 99 : 55} setVal={setNewValue} num={modes.SXF_AWA === 1 ? `AWAY_CLOCK` : 'SPDHW_ZAD'} step={1} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

        </React.Fragment>
    )
}

export default CWUCircuit