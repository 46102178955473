import React, { useState, useEffect } from 'react'
import { useLocation, Link, useRouteMatch } from 'react-router-dom'
import { Grid, Typography, Switch, Dialog, DialogContent, IconButton } from '@material-ui/core'
import Back from '../../img/powrot.svg'
import { Gauge } from '../Main/components/CenterSide/pages/EMSInfo/components'
import { iconDict } from '../Main/components/CenterSide/pages/EMSInfo/dict'
import { MeterDiagram } from './components'

const MeterInfo = ({ settings }) => {
  const [singleLocationMeter, setSingleLocationMeter] = useState(null)
  const [singleMeter, setSingleMeter] = useState(null)
  const [precentPowerState, setPrecentPowerState] = useState(null)
  const [precentCurrentState, setPrecentCurrentState] = useState(null)
  const [colorState, setColorState] = useState(null)
  let { url } = useRouteMatch()
  let location = useLocation()

  useEffect(() => {
    let mounted = true
    if (location && location.state && location.state.meter && settings) {
      setSingleLocationMeter(location.state)
      setSingleMeter(settings[location.state.meter.meterDevice.Name])
    }

    return () => {
      mounted = false
    }
  }, [settings])

  return (
    <React.Fragment>
      <Grid container id="meterInfo">
        <Grid item xs={12}>
        {console.log({location:location.state.infoURL})}
          <Typography variant="h3" style={{ display: 'inline-block' }}>Parametry zasilania - licznik {singleLocationMeter ? singleLocationMeter.name : null}</Typography>
          <div className='back-btn' style={{ marginTop: "20px" }}><Link to={{
            pathname: `${location.state.infoURL}`,
            state: {
              initInfo: singleLocationMeter ? singleLocationMeter.activeSlideSwiper : 0
            }
          }}><img style={{ width: '100%' }} alt="Powrót" src={Back} /></Link></div>
        </Grid>
      </Grid>
      <Grid container style={{ minHeight: '610px' }}>
        <Grid item xs={6} style={{ padding: "150px 0 0 40px" }}>
        {singleLocationMeter && singleLocationMeter.name === "pv"? null : <Grid container>
            <Grid item xs={6}><Typography style={{ textAlign: "right" }} variant="h5">Pobrana energia <br />
              <strong style={{ fontFamily: "Geogrotesque-SemiBold" }}>{singleMeter ?
                singleMeter.meter.POS_ACT_POW_FL_1.toLocaleString("fr-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) : null}</strong>&nbsp;&nbsp;kWh</Typography></Grid>
          </Grid>}
          {singleLocationMeter && singleLocationMeter.name === "pompa ciepła"? null : <Grid container>
            <Grid item xs={6}><Typography style={{ textAlign: "right" }} variant="h5">Oddana energia <br />
              <strong style={{ fontFamily: "Geogrotesque-SemiBold" }}>{singleMeter ?
                singleMeter.meter.REW_ACT_POW_FL_1.toLocaleString("fr-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) : null}</strong>&nbsp;&nbsp;kWh</Typography></Grid>
          </Grid>}
          <Grid container>
            <Grid item xs={6}><Typography style={{ textAlign: "right" }} variant="h5">Moc chwilowa <br />
              <strong style={{ fontFamily: "Geogrotesque-SemiBold" }}>{singleMeter ?
                singleMeter.meter.TOTAL_ACT_POW_FL_1.toLocaleString("fr-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) : null}</strong>&nbsp;&nbsp;kW</Typography></Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} style={{ paddingTop: "30px" }}>
          {singleMeter && singleMeter.meter ? <MeterDiagram valueMet={singleMeter ? singleMeter.meter : null} /> : null}
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "30px" }}>
        <Grid item xs={1}></Grid>
        <Grid item xs={11}>
          <Grid container>
            <Grid item xs={4}><Typography variant="body1" style={{ textAlign: "center", fontSize: "20px" }}>faza 1</Typography></Grid>
            <Grid item xs={4}><Typography variant="body1" style={{ textAlign: "center", fontSize: "20px" }}>faza 2</Typography></Grid>
            <Grid item xs={4}><Typography variant="body1" style={{ textAlign: "center", fontSize: "20px" }}>faza 3</Typography></Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1}><Typography variant="body1" style={{ textAlign: "right", fontSize: "20px", lineHeight: "220px" }}>moc</Typography></Grid>
        <Grid item xs={11}>
          <Grid container>
            <Grid item xs={4} style={{ padding: "0 25px" }}>
              {singleMeter && singleMeter.meter && singleMeter.meter.A_PHA_ACT_POW_FL_1 ?
                <Gauge addClass={true} symbol="kW" nameMeter={iconDict[singleMeter.meterDevice.Name].name} 
                valueMet={singleMeter ? singleMeter.meter.A_PHA_ACT_POW_FL_1 : null} 
                maxVal={11}
                valPrecentMeter={Math.round((Math.abs(singleMeter.meter.A_PHA_ACT_POW_FL_1) / 11) * 100)} />
                : null}
            </Grid>
            <Grid item xs={4} style={{ padding: "0 25px" }}>
              {singleMeter && singleMeter.meter && singleMeter.meter.B_PHA_ACT_POW_FL_1 ?
                <Gauge addClass={true} symbol="kW" nameMeter={iconDict[singleMeter.meterDevice.Name].name} 
                valueMet={singleMeter ? singleMeter.meter.B_PHA_ACT_POW_FL_1 : null} 
                maxVal={11}
                valPrecentMeter={Math.round((Math.abs(singleMeter.meter.B_PHA_ACT_POW_FL_1) / 11) * 100)} />
                : null}
            </Grid>
            <Grid item xs={4} style={{ padding: "0 25px" }}>
              {singleMeter && singleMeter.meter && singleMeter.meter.C_PHA_ACT_POW_FL_1 ?
                <Gauge addClass={true} symbol="kW" nameMeter={iconDict[singleMeter.meterDevice.Name].name} 
                valueMet={singleMeter ? singleMeter.meter.C_PHA_ACT_POW_FL_1 : null} 
                maxVal={11}
                valPrecentMeter={Math.round((Math.abs(singleMeter.meter.C_PHA_ACT_POW_FL_1) / 11) * 100)} />
                : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "10px" }}>
        <Grid item xs={1}><Typography variant="body1" style={{ textAlign: "right", fontSize: "20px", lineHeight: "220px" }}>prąd</Typography></Grid>
        <Grid item xs={11}>
          <Grid container>
            <Grid item xs={4} style={{ padding: "0 25px" }}>
              {singleMeter && singleMeter.meter && singleMeter.meter.A_PHA_CUR_FL_1 ?
                <Gauge addClass={true} symbol="A" nameMeter={iconDict[singleMeter.meterDevice.Name].name} 
                valueMet={singleMeter ? singleMeter.meter.A_PHA_CUR_FL_1 : null}
                maxVal={20} 
                valPrecentMeter={Math.round((Math.abs(singleMeter.meter.A_PHA_CUR_FL_1) / 20) * 100) >= 100? 100 
                : Math.round((Math.abs(singleMeter.meter.A_PHA_CUR_FL_1) / 20) * 100) <= 0? 0 
                : Math.round((Math.abs(singleMeter.meter.A_PHA_CUR_FL_1) / 20) * 100)} />
                : null}
            </Grid>
            <Grid item xs={4} style={{ padding: "0 25px" }}>
              {singleMeter && singleMeter.meter && singleMeter.meter.B_PHA_CUR_FL_1 ?
                <Gauge addClass={true} symbol="A" nameMeter={iconDict[singleMeter.meterDevice.Name].name} 
                valueMet={singleMeter ? singleMeter.meter.B_PHA_CUR_FL_1 : null} 
                maxVal={20}
                valPrecentMeter={Math.round((Math.abs(singleMeter.meter.B_PHA_CUR_FL_1) / 20) * 100) >= 100? 100 
                : Math.round((Math.abs(singleMeter.meter.B_PHA_CUR_FL_1) / 20) * 100) <= 0? 0 
                : Math.round((Math.abs(singleMeter.meter.B_PHA_CUR_FL_1) / 20) * 100)} />
                : null}
            </Grid>
            <Grid item xs={4} style={{ padding: "0 25px" }}>
              {singleMeter && singleMeter.meter && singleMeter.meter.C_PHA_CUR_FL_1 ?
                <Gauge addClass={true} symbol="A" nameMeter={iconDict[singleMeter.meterDevice.Name].name} 
                valueMet={singleMeter ? singleMeter.meter.C_PHA_CUR_FL_1 : null} 
                maxVal={20}
                valPrecentMeter={Math.round((Math.abs(singleMeter.meter.C_PHA_CUR_FL_1) / 20) * 100) >= 100 ? 100 
                : Math.round((Math.abs(singleMeter.meter.C_PHA_CUR_FL_1) / 20) * 100) <= 0? 0 
                : Math.round((Math.abs(singleMeter.meter.C_PHA_CUR_FL_1) / 20) * 100)} />
                : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default MeterInfo