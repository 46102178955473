import React, { useState, useEffect } from 'react'
import { Grid, Typography, Switch, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import setpoint_chlodzenie from '../../../../../../img/setpoint-chlodzenie.svg'
import setpoint_grzanie from '../../../../../../img/setpoint-grzanie.svg'
import setpoint_czuwanie from '../../../../../../img/setpoint-czuwanie.svg'
import setpoint_off from '../../../../../../img/CO-off.svg'
import edycja from '../../../../../../img/edit.svg'
import X from '../../../../../../img/x.svg'
import { mainStyleClasses } from '../../../../../../theme'

import { CarouselInputMulti } from '../../../../../../components'

const HeatingCircuitAdditional = ({ settings, setPublish, modes, num, urlName }) => {
    const classes = mainStyleClasses()
    const [state, setState] = useState(settings.SXF_TOE);
    const [itemValue, setItemValue] = useState(settings)
    const [open, setOpen] = useState({status: false, val: null, name: ""})
    const [gotopage, setGoToPage] = useState("")

    let realTemp = modes.SBF_OOF === 0 ? "off" : modes.SXF_ECO === 1 || modes.SXF_TIME === 1  || modes.SXB_PV === 1 ? itemValue[`OB${num}_TKMF_BAZ`] + itemValue.SCHT : modes.SXB_PV === 1 ? itemValue[`OB${num}_TKMF_BAZ`] + itemValue.SCHT : itemValue[`OB${num}_TKMF_BAZ`]

    const handleClickOpen = () => {
        if (modes.SXF_TIME === 1) {
            setGoToPage(`${urlName}/timemode/timeheating`)
        } else if (modes.EMS_DATA === true && modes.SXF_AWA !== 1 && modes.U_ENE_TAR === 1) {
            setGoToPage(`${urlName}/metersettings`)
        } else if (modes.SXF_ECO === 1 && modes.SXF_AWA !== 1) {
            setGoToPage(`${urlName}/ecomode`)
        } else if (modes.SBF_OOF === 0) {
            return
        } else {
            setOpen({val: modes.SXF_AWA === 1 ? settings.AWAY_CLOCK : settings.SPHT_ZAD, name: modes.SXF_AWA === 1 ? 'AWAY_CLOCK' : 'SPHT_ZAD', status: true})
        }
    }

    const handleClose = () => {
        setItemValue({ [open.name]: open.val })
        setPublish({ [open.name]: open.val })
        setOpen({ ...open, status: false})
    }
    const handleChange = () => {
        if (state === 0) {
            setState(1)
            setPublish({ SXF_TOE: 1 })
        } else {
            setState(0)
            setPublish({ SXF_TOE: 0 })
        }
    }

    const setNewValue = (num, val) => {
        setOpen({
            ...open,
            name: num,
            val: val
        })
    }

    useEffect(() => {
        let mounted = true

        // eslint-disable-next-line react-hooks/exhaustive-deps
        realTemp = modes.SBF_OOF === 0 ? "off" : modes.SXF_ECO === 1 || modes.SXF_TIME === 1  || modes.SXB_PV === 1 ? settings[`OB${num}_TKMF_BAZ`] + settings.SCHT : settings[`OB${num}_TKMF_BAZ`]
        
        setState(settings.SXF_TOE)
        setItemValue(settings)
        return () => {
            mounted = false
        }
    }, [settings, modes])

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={3} style={{ position: 'relative' }}>
                {settings.SXF_TOE === 1 && settings.SBF_OOF === 1 ?
                    settings.SBB_QHT === 1 ? <img alt="obieg grzewczy - grzanie" className="mode-icon" src={setpoint_grzanie} style={{ position: 'absolute', bottom: '0px', right: '25px' }} />
                        : settings.SBB_QHL === 1 ? <img alt="obieg grzewczy - chłodzenie" className="mode-icon" src={setpoint_czuwanie} style={{ position: 'absolute', bottom: '0px', right: '25px' }} />
                            : <img alt="obieg grzewczy - czuwanie" className="mode-icon" src={setpoint_czuwanie} style={{ position: 'absolute', bottom: '0px', right: '25px' }} />
                    : <img alt="obieg grzewczy - wyłączony" className="mode-icon" src={setpoint_off} style={{ position: 'absolute', bottom: '0px', right: '25px' }} />}
                </Grid>
                <Grid item xs={6}><Typography variant="h1">{modes.SBF_OOF === 0 ? <React.Fragment>off<sup> </sup></React.Fragment> : modes.SXF_AWA === 1 ? (<React.Fragment>{itemValue.AWAY_CLOCK}<sup>dni</sup></React.Fragment>) : (<React.Fragment>{parseInt(realTemp)}<sup>&deg;C</sup></React.Fragment>)}</Typography></Grid>
                <Grid item xs={3} style={{ position: 'relative' }}>
                    <img alt="edycja" onClick={handleClickOpen} className="mode-icon" src={edycja} style={{ position: 'absolute', bottom: '0px', left: '25px' }} />
                    {gotopage && gotopage !== "" ? <Redirect to={gotopage} /> : null}
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: '50px' }}>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ display: "inline-block", marginRight: '20px' }}>{`Obieg grzewczy ${parseInt(num) + 1}`}</Typography>
                </Grid>
                </Grid>
            <Dialog scroll={"paper"} fullWidth={true} maxWidth={"sm"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <img alt="X" style={{ height: '20px' }} src={X}/>
                    </IconButton>
                    <Grid container>
                        <Grid item xs={12}><Typography variant="h3">{modes.SXF_AWA === 1 ? "Liczba dni urlopu" : "Ustaw temperaturę"}</Typography></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6} className="carousel">
                            <CarouselInputMulti init={modes.SXF_AWA === 1 ? settings.AWAY_CLOCK : settings[`OB${num}_TKMF_BAZ`]} name={modes.SXF_AWA === 1 ? `AWAY_CLOCK` : `OB${num}_TKMF_BAZ`} min={modes.SXF_AWA === 1 ? 0 : 15} max={modes.SXF_AWA === 1 ? 99 : 25} setVal={setNewValue} num={modes.SXF_AWA === 1 ? `AWAY_CLOCK` : `OB${num}_TKMF_BAZ`} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

        </React.Fragment>
    )
}

export default HeatingCircuitAdditional