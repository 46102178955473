import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { iconDict } from './dict'
import { FrameMeter } from './components'

const meterComponentsList = (meterSettings) => {
    let meterList = Object.entries(meterSettings).map((item, index) => {
        if (item[1].meterDevice)
            return item[1].meterDevice.Name
    })

    return meterList
}
const EMSInfo = ({ meterSettings, activeSlideSwiper, urlName }) => {
    
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={4}><div className="frameMeter"><FrameMeter urlName={urlName} activeSlideSwiper={activeSlideSwiper} numberMeter={1} meter={meterSettings && meterSettings.METER_1 ? meterSettings.METER_1 : null} /></div></Grid>
                <Grid item xs={4}><div className="frameMeter"><FrameMeter urlName={urlName} activeSlideSwiper={activeSlideSwiper} numberMeter={2} meter={meterSettings && meterSettings.METER_2 ? meterSettings.METER_2 : null} /></div></Grid>
                <Grid item xs={4}><div className="frameMeter"><FrameMeter urlName={urlName} activeSlideSwiper={activeSlideSwiper} numberMeter={3} meter={meterSettings && meterSettings.METER_3 ? meterSettings.METER_3 : null} /></div></Grid>
                <Grid item className={`aplaMeter ${meterSettings && Object.entries(meterSettings).length >= 4 ? "hide" : null}`} style={{right: meterSettings ? !meterComponentsList(meterSettings).includes('METER_2') && meterComponentsList(meterSettings).includes('METER_3')? '33%' : 0 : 0}}
                    xs={meterSettings && meterSettings.general.EMS_DATA && Object.entries(meterSettings).length >= 4 ? 0 : meterSettings && meterSettings.general.EMS_DATA && Object.entries(meterSettings).length === 3 ? 4 : meterSettings && meterSettings.general.EMS_DATA && Object.entries(meterSettings).length === 2 ? 8 : 12}
                >
                    <Typography variant="body1" style={{ textAlign: "center", marginTop: "50px", fontSize: "18px" }}>
                        Obniż rachunki za prąd nawet o 30% dzięki<br />
                        Euros Expert EMS.<br />
                        Chcesz wiedzieć więcej?<br />
                        Napisz maila na kontakt@eurosenergy.com <br />lub wejdź na stronę www.eurosenergy.com/ems/
                    </Typography>
                    <img alt="ems-link" className="mode-icon" style={{ borderRadius: "8px", marginTop: "40px" }} src={iconDict.EMSLINK} />
                </Grid>
                
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ display: "inline-block", marginRight: '20px', marginTop: '0px' }}>Liczniki</Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default EMSInfo