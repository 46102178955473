import React, { useState, useEffect } from 'react'
import { Grid, Typography, Dialog, DialogContent, IconButton, Switch } from '@material-ui/core'
import { useRouteMatch } from "react-router-dom"
import { Link } from 'react-router-dom'
import { mainStyleClasses } from '../../theme'
import Back from '../../img/powrot.svg'
import X from '../../img/x.svg'
import OWL from '../../img/owl-02.svg'
import { CarouselInputMulti } from '../../components'
import { tariff } from '../../dict/tariffDict'

const MeterSettings = ({ settings, setPublish, settingsEms, urlName }) => {
    const [meterSettings, setMeterSettings] = useState(null)
    const [tariffNumber, setTariffNumber] = useState(null)
    const [open, setOpen] = useState({ status: false, name: "", init: 0, min: 0, max: 0, num: "", title: "", step: 1 })
    let { url } = useRouteMatch()
    const classes = mainStyleClasses()

    const handleClickOpen = (name, init, min, max, num, title, step) => {
        setOpen({ status: true, name: name, init: init, min: min, max: max, num: num, title: title, step: step })
    }

    const handleClose = () => {
        setOpen({ ...open, status: false })
    }

    const setNewTariff = (val, name) => {
        setMeterSettings({
            ...meterSettings,
            general: {
                ...meterSettings.general,
                [open.name]: typeof val == "string" ? val : val
            }
        })
        setPublish({ [open.name]: typeof val == "string" ? val : val })
    }

    const handleChange = (e) => {
        if (meterSettings.general[e.name] === 1) {
            setMeterSettings({
                ...meterSettings,
                general: {
                    ...meterSettings.general,
                    [e.name]: 0
                }
            })
            setPublish({ [e.name]: 0 })
        } else {
            setMeterSettings({
                ...meterSettings,
                general: {
                    ...meterSettings.general,
                    [e.name]: 1
                }
            })
            setPublish({ [e.name]: 1 })
        }
    }

    useEffect(() => {
        let mounted = true

        if (settings) {
            setMeterSettings(settings)

            let numberTariffEnergy = tariff.tariffDictEnergy.findIndex((el) => el === settings.general.ENE_TAR)
            let numberTariffDistribution = tariff.tariffDictDistribution.findIndex((el) => el === settings.general.DIS_TAR)

            setTariffNumber({ tariffNumberEnergy: numberTariffEnergy, tariffNumberDistribution: numberTariffDistribution })
        } else if (settingsEms) {
            setMeterSettings({general: settingsEms})
            let numberTariffEnergy = tariff.tariffDictEnergy.findIndex((el) => el === settingsEms.ENE_TAR)
            let numberTariffDistribution = tariff.tariffDictDistribution.findIndex((el) => el === settingsEms.DIS_TAR)

            setTariffNumber({ tariffNumberEnergy: numberTariffEnergy, tariffNumberDistribution: numberTariffDistribution })
        }

        return () => {
            mounted = false
        }
    }, [settings, settingsEms])


    return (
        <React.Fragment>
        {console.log({urlName})}
        {console.log("ddddddd")}
            <Grid container>
                <Grid item xs={12}>
                    <img alt="trybems" className="mode-icon" style={{ display: 'inline-block', float: "left", marginLeft: "75px", width: "90px"}} src={OWL} />
                    <Typography variant="h3" style={{ display: 'inline-block', lineHeight: "105px", float: "left", margin: "0 30px" }}>Asystent energetyczny</Typography>
                    <div className='back-btn' style={{ marginTop: "20px" }}><Link to={`${urlName}`}><img style={{ width: '100%' }} alt="Powrót" src={Back} /></Link></div>
                </Grid>
            </Grid>

            <Grid container style={{ padding: "0 30px", marginTop: "30px" }}>
                <Grid item xs={10} className="setting-item" style={{ borderTop: "0" }}><Typography variant="body1">Średnia temperatura powietrza</Typography></Grid>
                <Grid item xs={2} className="setting-item" style={{ borderTop: "0" }}>
                    <Typography variant="body1" onClick={() => handleClickOpen("SPHT_ZAD", meterSettings.general.SPHT_ZAD, 10, 30, meterSettings.general.SPHT_ZAD, "Średnia temp. powietrza", 0.5)} className="edit-box" style={{ padding: 0, width: 120.60 }}>{meterSettings ? meterSettings.general.SPHT_ZAD : 0}</Typography>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item" ><Typography variant="body1">Współczynnik komfort-ekonomia dla CO</Typography></Grid>
                <Grid item xs={2} className="setting-item">
                    <Typography variant="body1" onClick={() => handleClickOpen("CO_ECO_COEFF", meterSettings.general.CO_ECO_COEFF, 0, 10, meterSettings.general.CO_ECO_COEFF, "Ekonomia CO", 0.5)} className="edit-box" style={{ padding: 0, width: 120.60 }}>{meterSettings ? meterSettings.general.CO_ECO_COEFF : 0}</Typography>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item"><Typography variant="body1">Średnia temperatura wody</Typography></Grid>
                <Grid item xs={2} className="setting-item">
                    <Typography variant="body1" onClick={() => handleClickOpen("SPDHW_ZAD", meterSettings.general.SPDHW_ZAD, 35, 55, meterSettings.general.SPDHW_ZAD , "Średnia temp. wody", 1)} className="edit-box" style={{ padding: 0, width: 120.60 }}>{meterSettings ? meterSettings.general.SPDHW_ZAD : 0}</Typography>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item"><Typography variant="body1">Współczynnik komfort-ekonomia dla CWU</Typography></Grid>
                <Grid item xs={2} className="setting-item">
                    <Typography variant="body1" onClick={() => handleClickOpen("CWU_ECO_COEFF", meterSettings.general.CWU_ECO_COEFF, 0, 10, meterSettings.general.CWU_ECO_COEFF, "Ekonomia CWU", 0.5)} className="edit-box" style={{ padding: 0, width: 120.60 }}>{meterSettings ? meterSettings.general.CWU_ECO_COEFF  : 0}</Typography>
                </Grid>
            </Grid>

            <Grid container style={{ padding: "0 30px"}}>
                <Grid item xs={10} className="setting-item"><Typography variant="body1">Wybrana taryfa energetyczna</Typography></Grid>
                <Grid item xs={2} className="setting-item">
                    <Typography variant="body1" onClick={() => handleClickOpen("tariffEnergy", tariffNumber.tariffNumberEnergy, 0, tariff.tariffDictEnergy.length - 1, tariffNumber.tariffNumberEnergy, "Taryfa energetyczna", 1)} className="edit-box" style={{ padding: 0, width: 120.60 }}>{meterSettings ? meterSettings.general.ENE_TAR : null}</Typography>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item"><Typography variant="body1">Wybrana taryfa dystrybucyjna</Typography></Grid>
                <Grid item xs={2} className="setting-item">
                    <Typography variant="body1" onClick={() => handleClickOpen("tariffDistribution", tariffNumber.tariffNumberDistribution, 0, tariff.tariffDictDistribution.length - 1, tariffNumber.tariffNumberDistribution, "Taryfa dystrybucyjna", 1)} className="edit-box" style={{ padding: 0, width: 120.60 }}>{meterSettings ? meterSettings.general.DIS_TAR : null}</Typography>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 30px" }}>
                <Grid item xs={10} className="setting-item" style={{ borderBottom: "0" }}><Typography variant="body1">Analiza PV</Typography></Grid>
                <Grid item xs={2} className="setting-item" style={{ borderBottom: "0" }}>
                    <Switch
                        style={{ display: "block" }}
                        checked={meterSettings? meterSettings.general.U_PV_DATA === 1 ? true : false : false}
                        onChange={(e) => handleChange(e.target)}
                        color="primary"
                        name="U_PV_DATA"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
            </Grid>

            <Dialog scroll={"paper"} fullWidth={true} maxWidth={"sm"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <img alt="X" style={{ height: '20px' }} src={X} />
                    </IconButton>
                    <Grid container>
                        <Grid item xs={12}><Typography variant="h3">{open.title}</Typography></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6} className="carousel">
                            <CarouselInputMulti init={open.init} name={open.name} min={open.min} max={open.max} setVal={setNewTariff} num={open.num} step={open.step} />
                        </Grid>
                        <Grid item xs={3}></Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default MeterSettings