import meter_1 from '../../../../../../../img/meter_1.svg'
import meter_2 from '../../../../../../../img/meter_2.svg'
import meter_3 from '../../../../../../../img/meter_3.svg'
import parametry from '../../../../../../../img/parametry.svg'
import emslink from '../../../../../../../img/EMS-link.svg'

export default {
    METER_1: {
        name: "dom",
        img: meter_1,
    },
    METER_2: {
        name: "pv",
        img: meter_2,
    },
    METER_3: {
        name: "pompa ciepła",
        img: meter_3,
    },
    PARAMETRY: parametry,
    EMSLINK: emslink
}