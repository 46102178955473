import { decodeToken } from "react-jwt"
//Json z danymi sterowników od brokera
class JsonDeviceHeader {

    Name
    Type
    Version
}

class JsonDevice extends JsonDeviceHeader {

    Data
}
class Modification {

    App
    User
}

class JsonObject {

    Token
    TimeStamp
    ExpirationTime
    Modification
    Devices = []
}

function GenerateToken() {

    return Math.floor(Math.random() * (999999999 - 100000000 + 1) + 100000000)
}

// module.exports = {

//     GetJson: function (Values, Name, Type, Version) {

//         try {

//             let JData = JSON.parse(Values)
//             let JObject = new JsonObject()
//             let JDev = new JsonDevice()
//             JDev.Data = {}

//             for (let [key, value] of Object.entries(JData.Data)) {

//                 JDev.Data[key] = value
//             }

//             JDev.Data["TOKEN"] = GenerateToken().toString();

//             JDev.Name = Name
//             JDev.Type = Type
//             JDev.Version = Version
//             JObject.Token = JData.Token.toString()
//             JObject.TimeStamp = new Date().toISOString()
//             JObject.ExpirationTime = 0
//             JObject.Devices = [JDev]

//             return JSON.stringify(JObject)
//         }
//         catch{

//             return null
//         }
//     }
// }decodeToken(sessionStorage.getItem("APIToken")).sub

export const PublishSetupModule = (Values, Name, Type, Version) => {
    try {

        let JData = JSON.parse(Values)
        let JObject = new JsonObject()
        let JDev = new JsonDevice()
        JObject.Modification = new Modification()
        JDev.Data = {}

        for (let [key, value] of Object.entries(JData.Data)) {

            JDev.Data[key] = value
        }

        JDev.Data["TOKEN"] = GenerateToken().toString();

        JDev.Name = Name
        JDev.Type = Type
        JDev.Version = Version
        JObject.Token = JData.Token.toString()
        JObject.TimeStamp = new Date().toISOString()
        JObject.ExpirationTime = 0
        JObject.Modification.App = "PM-EUROS, EurosBox"
        JObject.Modification.User = `${decodeToken(sessionStorage.getItem("APIToken")).sub}`
        JObject.Devices = [JDev]

        return JSON.stringify(JObject)
    }
    catch {

        return null
    }
}